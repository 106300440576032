import jose_img_1 from '../../images/Blind-angels-jose-joaquin-profile.png';
import jose_img_1_mobile from '../../images/Blind-angels-jose-joaquin-profile_mobile.png';

const copyListJose = [
  {
    type: 'text',
    content: "José Romero and Joaquín Carcaño are HIV advocates living in Durham, North Carolina, and working with the Hispanic/Latino community throughout the American South. The two, who are not just colleagues but “chosen family,” appear in the second episode of <i>Blind Angels</i>, a new docuseries on HIV in the American South, which is produced by Courageous Studios in partnership with Gilead Sciences. José and Joaquín understand the unique cultural and social challenges that can prevent members of their community from being aware of and accessing HIV prevention, treatment, and care. Their work focuses on overcoming language barriers and other challenges in order to increase the use of prevention services and PrEP medicines in this community."
  },
  {
    type: "image",
    content: {
      src: jose_img_1,
      mobileSrc: jose_img_1_mobile,
      alt: "Jose and Joaquin sitting and looking at the camera",
      caption: "Joaquín Carcaño and José Romero"
    }
  },
  {
    type: 'text',
    content: "Joaquín is the director of community organizing for the Latinos in the South program coordinated by the Latino Commission on AIDS, which works in nine southern states from Texas to North Carolina. José recently left the Commission to serve as a consultant to health departments across the United States advising on language and racial justice issues. The two sat down for an exclusive interview with Gilead Sciences to discuss the barriers to PrEP medicine use that they see every day."
  },
  {
    type: "blockquote",
    content: "In 2016, among PrEP medicine users in the US with with available race/ethnicity data, only ~13% were Hispanic American. In contrast, ~69%, ~11%, and ~5% were White, Black, and Asian American, respectively.<sup>1</sup>"
  },
  {
    type: "text",
    content: "Perhaps the most obvious challenge for those in the Hispanic/Latino American community is the potential language barrier between those seeking HIV care and their healthcare providers. Data suggest that, in 2015, 1/3 of Hispanics living in the US have limited English proficiency, and yet all HIV testing locations or clinics may not offer translation services.<sup>2,3</sup> In fact, in this episode of <i>Blind Angels</i>, José is shown serving as a translator for a friend who is getting tested for the first time. Had José not been present, their friend may not have been able to ask questions and may have left the appointment without the information needed."
  },
  {
    type: "text",
    content: "Having healthcare providers who speak Spanish or knowledgeable translators as well as written materials and posters available in Spanish is an important first step in helping people in these communities to understand their HIV risk, adopt prevention strategies, and adhere to any treatment protocols they may need.<sup>3-5</sup> José noted that, for example, the word “once” in English is the same as the word “eleven” in Spanish; “That makes a big difference when you’re supposed to be taking a daily medication.”"
  },
  {
    type: "text",
    content: "It’s not enough, however, to stop at translations. Materials need to be culturally appropriate and relevant to this community.<sup>3,6</sup> José recalled a campaign for PrEP medicine that had been translated into Spanish but clearly didn’t consider the cultural implications of language or how to reach the Hispanic/Latino community appropriately. Despite being able to read the words, the audience did not see themselves reflected in the campaign and were not motivated by its messages."
  },
  {
    type: "blockquote",
    content: "In 2019, Latino/x Americans had an HIV diagnosis rate of about 22 diagnoses per 100,000 people, which was 4 times the rate of White Americans.<sup>7</sup>",
  },
  {
    type: "text",
    content: "In addition to the language barrier, many in the Hispanic/Latino community in the South are among the 11.4 million undocumented immigrants living in this country, according to 2018 data.<sup>8</sup> Undocumented immigrants are often reluctant to seek healthcare or other services based on fears that they will expose their immigration status and risk deportation.<sup>3</sup> Joaquín talked about the misinformation that their organization has had to combat. Recently, there were false rumors that people would be required to provide a driver’s license in order to get a COVID-19 vaccine or even a test. Similar misinformation and fears may keep undocumented immigrants from seeking HIV care.<sup>3</sup> Joaquín explained: “It can be really difficult for people to advocate for themselves, for them to feel safe in those spaces, to feel like they're allowed to access some of these services.”"
  },
  {
    type: "text",
    content: "Hispanics/Latino Americans also have a much higher uninsured rate than White Americans in the US. Those who are undocumented are not able to get Medicaid or buy health insurance plans under the Affordable Care Act. This can result in limited access to preventive care in all areas of their health, including HIV.<sup>9</sup> José pointed out that many of the people they work with do not have a primary care doctor. This is why some of their work focuses on expanding the idea of who can be considered a healthcare provider and an HIV advocate—particularly pharmacists, who are often already a trusted source of medical information."
  },
  {
    type: "text",
    content: "José’s work is part of a growing movement to allow pharmacists to provide PrEP medicine. Pharmacists are knowledgeable and accessible and often have longstanding relationships with members of the communities they serve. This puts them in a unique position to help people evaluate their HIV risk factors, understand if PrEP medicine is appropriate for them, and adhere to a regimen if they choose to use it and safer sex practices.<sup>10</sup> Colorado and Nevada have passed laws to allow pharmacists to dispense PrEP medicine, and California permits pharmacists to dispense PrEP medicine without a prescription for 30-60 days after certain requirements are met, like a negative HIV test and follow-up counseling.<sup>11-15</sup> According to José, however, their advocacy efforts are about more than trying to expand who can write prescriptions as they hope to “invite other folks who are part of the healthcare system to really see themselves as HIV advocates.”"
  }
]


export default copyListJose;